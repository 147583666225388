import { FETCH_RESULTS } from '../actions/types';

const RESULTS_DEFAULT_STATE = [];

const ResultsReducer = (state = RESULTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_RESULTS:
            return payload;

        default:
            return state;
    }
};

export default ResultsReducer;
