import { SET_USER, CLEAN_USER } from '../actions/types';

const USER_DEFAULT_STATE = [];

const UserReducer = (state = USER_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case SET_USER:
            if (typeof window.LiveAgent === 'function') {
                window.LiveAgent.addTicketField('email', payload.email);
            }

            return payload;

        case CLEAN_USER:
            return USER_DEFAULT_STATE;

        default:
            return state;
    }
};

export default UserReducer;
