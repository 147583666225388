import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Route, Routes } from 'react-router-dom';
import Footer from '../components/Footer';
import { Navigate } from './Navigate';
import PublicScreen from '../screens/PublicScreen';
import LoginScreen from "../screens/LoginScreen";
import RegisterScreen from "../screens/RegisterScreen";
import DashboardScreen from "../screens/DashboardScreen";
import '../assets/styles/content.css';

class Content extends Navigate {

    render() {
        const { location, navigate, params } = this.props;

        return (
            <div className="content">
                <div className="content__screen">
                    <Routes>
                        <Route path="/dashboard" element={<DashboardScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/register" element={<RegisterScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/login" element={<LoginScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="*" element={<PublicScreen location={location} navigate={navigate} params={params} />} />
                    </Routes>
                </div>
                <Footer location={location} navigate={navigate} params={params} />
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(Content));
