import { round } from './round';

export const toFixed = (number, decimals = 2) => {
    number = round(number, decimals);
    let negative = number < 0;

    number = number.toString();

    const find_decimals = /\.(.*)/.exec(number);
    let d = '';

    if (find_decimals !== null && find_decimals.length === 2) {
        d = find_decimals[1];
    }

    if (d.length !== decimals) {
        while (d.length < decimals) {
            d += '0';
        }
    }

    return `${negative ? '-' : ''}${Math.floor(Math.abs(number))}.${d}`;
};
