import { getAssets } from './functions/getAssets';

export const IMAGES = getAssets(require.context('./assets/img/', false, /\.(png|jpe?g|svg|gif)$/));

export const COOKIE_TOKEN_NAME = 'detectgoals-token';

export const COOKIE_SETTINGS = process.env.NODE_ENV && process.env.NODE_ENV === 'production'
    ? { path: '/', domain: window.location.host.replace('www.', '') }
    : { path: '/' };

export const PASSWORD_MIN_LENGTH = 8;

export const DEVELOPMENT_URL = 'https://api.detectgoals.com';

export const PRODUCTION_URL = 'https://api.detectgoals.com';
