import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import Menu from '../components/Menu';
import {Input, Message, Screen} from '../components';
import { fetchResults, setUser } from '../actions';
import {__, formatAmount, toNumber} from '../functions';
import '../assets/styles/public.css';
import {ButtonBase, TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from "@mui/material";
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import ReactCountryFlag from "react-country-flag";
import _ from 'lodash';

class PublicScreen extends Screen {
    authRequired = false;
    state = {
        league: '',
        year: '',
        month: '',
        amount: '200',
        rate: '2.4',
        lightbox: {
            player: false,
        },
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.league === '' && !_.isEmpty(this.props.results)) {
            this.changeLeague(_.keys(this.props.results)[0]);
        }
    }

    changeLeague(league) {
        const leaguePeriods = this.getLeaguePeriods(league);

        this.setState({ league, year: leaguePeriods.year, month: leaguePeriods.month });
    }

    changeYear(year) {
        const { league } = this.state;

        const lastMonth = this.getLeagueLastMonth(league, year);

        this.setState({ year, month: lastMonth });
    }

    changeMonth(month) {
        this.setState({ month });
    }

    getLeaguePeriods(league) {
        const { results } = this.props;

        const lastYear = results[league][results[league].length - 1].year;
        const lastMonth = this.getLeagueLastMonth(league, lastYear);

        return { year: lastYear, month: lastMonth };
    }

    getLeagueLastMonth(league, leagueYear) {
        const { results } = this.props;

        let lastMonth = '';

        _.each(results[league], ({ year, data }) => {
            if (toNumber(year) === toNumber(leagueYear)) {
                lastMonth = _.keys(data.occurencies)[_.keys(data.occurencies).length - 1];
            }
        });

        return lastMonth;
    }

    time(seconds) {
        seconds = Math.ceil(seconds);

        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${minutes}:${seconds}`;
    }

    changeAmount(amount) {
        amount = amount.replace(/[^0-9]/g, '');

        this.setState({ amount });
    }

    changeRate(rate) {
        rate = rate.replace(/[^0-9\.]/g, '');

        this.setState({ rate });
    }

    renderScreen() {
        const { location, navigate, params, results } = this.props;
        const { league, year, month, amount, rate, lightbox } = this.state;

        if (_.isEmpty(league)) {
            return this.renderLoading();
        }

        let yearData = null;
        let nhlTotal = 0;
        let nhlGoals = 0;

        _.each(results[league], item => {
            if (toNumber(item.year) === toNumber(year)) {
                yearData = item.data;
            }

            _.each(item.data.occurencies, v => {
                nhlTotal += v;
            });

            _.each(item.data.goals, v => {
                nhlGoals += v;
            });
        });

        if (nhlTotal === 0) {
            nhlTotal = 1;
        }

        const nhlProfit = (nhlGoals * (toNumber(amount) * toNumber(rate))) - (nhlTotal * toNumber(amount));

        return (
            <div className="public">
                <Menu
                    location={location}
                    navigate={navigate}
                    params={params}
                />
                <div className="public__title">{__('AI Výsledky')}</div>
                <div className="public__text">{__('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et feugiat odio. Nunc tempor malesuada tellus. Donec lacinia varius efficitur. Nullam mauris arcu, viverra eu orci vitae, venenatis pulvinar nulla.')}</div>
                <Message type="success" className="public__freemessage">{__('Všetky AI predikcie sú v tejto sezóne pre vás zadarmo! Stačí sa len zaregistrovať.')}</Message>
                <div className="public__inputs">
                    <Input
                        label={__('Vklad')}
                        value={amount}
                        onChange={value => this.changeAmount(value)}
                    />
                    <Input
                        label={__('Priemerný kurz')}
                        value={rate}
                        onChange={value => this.changeRate(value)}
                    />
                </div>
                <div className="public__subtitle">{__('Hokejové ligy')}</div>
                <div className="public__subtext">{__('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et feugiat odio. Nunc tempor malesuada tellus.')}</div>
                <div className="public__leagues">
                    <ButtonBase
                        onClick={() => this.changeLeague('nhl')}
                        className={`public__leagues__league ${league === 'nhl' ? 'active' : ''}`}
                    >
                        <div className="public__leagues__league__name">
                            <div className="public__leagues__league__name__left">
                                <span>{__('NHL')}</span>
                                <ReactCountryFlag countryCode="CA" svg style={{ width: '1rem', height: '1rem' }} title="Canada" />
                                <ReactCountryFlag countryCode="US" svg style={{ width: '1rem', height: '1rem' }} title="United states" />
                            </div>
                            <div className="public__leagues__league__name__right green">{__('Otvorené')}</div>
                        </div>
                        <div className="public__leagues__league__stats">
                            <div className="public__leagues__league__stats__stat">
                                <div className="public__leagues__league__stats__stat__name">{__('Počet sezón')}:</div>
                                <div className="public__leagues__league__stats__stat__value">{results.nhl.length}</div>
                            </div>
                            <div className="public__leagues__league__stats__stat">
                                <div className="public__leagues__league__stats__stat__name">{__('Počet udalostí')}:</div>
                                <div className="public__leagues__league__stats__stat__value">{nhlTotal}</div>
                            </div>
                            <div className="public__leagues__league__stats__stat">
                                <div className="public__leagues__league__stats__stat__name">{__('Počet gólov')}:</div>
                                <div className="public__leagues__league__stats__stat__value">{nhlGoals}</div>
                            </div>
                            <div className="public__leagues__league__stats__stat yellow">
                                <div className="public__leagues__league__stats__stat__name">{__('Úspešnosť')}:</div>
                                <div className="public__leagues__league__stats__stat__value">{formatAmount(nhlGoals / nhlTotal * 100, 2, ',', false)}%</div>
                            </div>
                            <div className={`public__leagues__league__stats__stat ${nhlProfit > 0 ? 'green' : 'red'}`}>
                                <div className="public__leagues__league__stats__stat__name">{__('Profit')}:</div>
                                <div className="public__leagues__league__stats__stat__value">{nhlProfit > 0 ? '+' : ''}{formatAmount(nhlProfit)}</div>
                            </div>
                        </div>
                    </ButtonBase>
                    <ButtonBase
                        onClick={() => {}}
                        className="public__leagues__league"
                    >
                        <div className="public__leagues__league__name">
                            <div className="public__leagues__league__name__left">
                                <span>{__('SHL')}</span>
                                <ReactCountryFlag countryCode="SE" svg style={{ width: '1rem', height: '1rem' }} title="Sweden" />
                            </div>
                            <div className="public__leagues__league__name__right">{__('Zatvorené')}</div>
                        </div>
                        <div className="public__leagues__league__stats">
                            <div className="public__leagues__league__stats__working">{__('Pracujeme na tom')}</div>
                        </div>
                    </ButtonBase>
                    <ButtonBase
                        onClick={() => {}}
                        className="public__leagues__league"
                    >
                        <div className="public__leagues__league__name">
                            <div className="public__leagues__league__name__left">
                                <span>{__('Liiga')}</span>
                                <ReactCountryFlag countryCode="FI" svg style={{ width: '1rem', height: '1rem' }} title="Finland" />
                            </div>
                            <div className="public__leagues__league__name__right">{__('Zatvorené')}</div>
                        </div>
                        <div className="public__leagues__league__stats">
                            <div className="public__leagues__league__stats__working">{__('Pracujeme na tom')}</div>
                        </div>
                    </ButtonBase>
                    <ButtonBase
                        onClick={() => {}}
                        className="public__leagues__league"
                    >
                        <div className="public__leagues__league__name">
                            <div className="public__leagues__league__name__left">
                                <span>{__('ELH')}</span>
                                <ReactCountryFlag countryCode="CZ" svg style={{ width: '1rem', height: '1rem' }} title="Czechia" />
                            </div>
                            <div className="public__leagues__league__name__right">{__('Zatvorené')}</div>
                        </div>
                        <div className="public__leagues__league__stats">
                            <div className="public__leagues__league__stats__working">{__('Pracujeme na tom')}</div>
                        </div>
                    </ButtonBase>
                </div>
                <div className="public__subtitle">{__('Sezóny')}</div>
                <div className="public__subtext">{__('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et feugiat odio. Nunc tempor malesuada tellus.')}</div>
                <div className="public__seasons">
                    {_.map(results[league], (item, key) => {
                        const active = item.year === year;

                        let total = 0;
                        let goals = 0;

                        _.each(item.data.occurencies, v => {
                            total += v;
                        });

                        _.each(item.data.goals, v => {
                            goals += v;
                        });

                        if (total === 0) {
                            total = 1;
                        }

                        const profit = (goals * (toNumber(amount) * toNumber(rate))) - (total * toNumber(amount));

                        return (
                            <ButtonBase
                                onClick={() => this.changeYear(item.year)}
                                className={`public__seasons__season ${active ? 'active' : ''}`}
                                key={key}
                            >
                                <div className="public__seasons__season__name">{item.year}</div>
                                <div className="public__seasons__season__stats">
                                    <div className="public__seasons__season__stats__stat">
                                        <div className="public__seasons__season__stats__stat__name">{__('Počet udalostí')}:</div>
                                        <div className="public__seasons__season__stats__stat__value">{total}</div>
                                    </div>
                                    <div className="public__seasons__season__stats__stat">
                                        <div className="public__seasons__season__stats__stat__name">{__('Počet gólov')}:</div>
                                        <div className="public__seasons__season__stats__stat__value">{goals}</div>
                                    </div>
                                    <div className="public__seasons__season__stats__stat yellow">
                                        <div className="public__seasons__season__stats__stat__name">{__('Úspešnosť')}:</div>
                                        <div className="public__seasons__season__stats__stat__value">{formatAmount(goals / total * 100, 2, ',', false)}%</div>
                                    </div>
                                    <div className={`public__seasons__season__stats__stat ${profit > 0 ? 'green' : 'red'}`}>
                                        <div className="public__seasons__season__stats__stat__name">{__('Profit')}:</div>
                                        <div className="public__seasons__season__stats__stat__value">{profit > 0 ? '+' : ''}{formatAmount(profit)}</div>
                                    </div>
                                </div>
                            </ButtonBase>
                        );
                    })}
                </div>
                <div className="public__subtitle">{__('Mesiace')}</div>
                <div className="public__subtext">{__('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et feugiat odio. Nunc tempor malesuada tellus.')}</div>
                <div className="public__months">
                    {_.map(yearData.occurencies, (item, yearMonth) => {
                        const active = yearMonth === month;

                        const total = item;
                        const goals = yearData.goals[yearMonth];
                        const profit = (goals * (toNumber(amount) * toNumber(rate))) - (total * toNumber(amount));

                        return (
                            <ButtonBase
                                onClick={() => this.changeMonth(yearMonth)}
                                className={`public__months__month ${active ? 'active' : ''}`}
                                key={yearMonth}
                            >
                                <div className="public__months__month__name">{yearMonth}</div>
                                <div className="public__months__month__stats">
                                    <div className="public__months__month__stats__stat">
                                        <div className="public__months__month__stats__stat__name">{__('Počet udalostí')}:</div>
                                        <div className="public__months__month__stats__stat__value">{total}</div>
                                    </div>
                                    <div className="public__months__month__stats__stat">
                                        <div className="public__months__month__stats__stat__name">{__('Počet gólov')}:</div>
                                        <div className="public__months__month__stats__stat__value">{goals}</div>
                                    </div>
                                    <div className="public__months__month__stats__stat yellow">
                                        <div className="public__months__month__stats__stat__name">{__('Úspešnosť')}:</div>
                                        <div className="public__months__month__stats__stat__value">{formatAmount(goals / total * 100, 2, ',', false)}%</div>
                                    </div>
                                    <div className={`public__months__month__stats__stat ${profit > 0 ? 'green' : 'red'}`}>
                                        <div className="public__months__month__stats__stat__name">{__('Profit')}:</div>
                                        <div className="public__months__month__stats__stat__value">{profit > 0 ? '+' : ''}{formatAmount(profit)}</div>
                                    </div>
                                </div>
                            </ButtonBase>
                        );
                    })}
                </div>
                <div className="public__subtitle">{__('Udalosti')}</div>
                <div className="public__subtext">{__('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et feugiat odio. Nunc tempor malesuada tellus.')}</div>
                <div className="public__players">
                    {_.map(yearData.players, (item, key) => {
                        if (item.md.indexOf(month) === -1) {
                            return null;
                        }

                        const win = toNumber(item.pg) > 0;
                        let playerAmount = toNumber(amount);

                        if (win) {
                            playerAmount *= toNumber(rate);
                        }

                        return (
                            <ButtonBase onClick={() => this.showLightbox('player', { data: item })} className="public__players__player" key={key}>
                                <div className="public__players__player__date">{item.md}</div>
                                <div className="public__players__player__info">
                                    <div className="public__players__player__info__bio">
                                        <div className="public__players__player__info__bio__name">{item.pn}</div>
                                        <div className="public__players__player__info__bio__team">{item.pt} {__('vs')} {item.ot}</div>
                                    </div>
                                    <div className="public__players__player__info__stats">
                                        <div className="public__players__player__info__stats__stat">{__('Formácia')}: {item.pf}</div>
                                        <div className="public__players__player__info__stats__stat">{__('AI ranking')}: {formatAmount(item.p, 8, ',', false)}</div>
                                    </div>
                                    <ReadMoreIcon />
                                </div>
                                <div className={`public__players__player__right ${win ? 'green' : 'red'}`}>{win ? '+' : '-'}{formatAmount(playerAmount)}</div>
                            </ButtonBase>
                        );
                    })}
                </div>
                {this.renderLightbox(
                    'player',
                    __('Základné štatistiky hráča'),
                    !_.isEmpty(lightbox.player) ? <div className="player__window">
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>{__('Celkovo')}</TableCell>
                                        <TableCell>{__('10 zápasov')}</TableCell>
                                        <TableCell>{__('5 zápasov')}</TableCell>
                                        <TableCell>{__('3 zápasy')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{__('Počet gólov (priemer)')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.bapg / lightbox.player.data.bapm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10pg / lightbox.player.data.b10pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5pg / lightbox.player.data.b5pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3pg / lightbox.player.data.b3pm, 4, ',', false)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('Počet striel (priemer)')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.baps / lightbox.player.data.bapm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10ps / lightbox.player.data.b10pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5ps / lightbox.player.data.b5pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3ps / lightbox.player.data.b3pm, 4, ',', false)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('Počet asistencií (priemer)')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.bapa / lightbox.player.data.bapm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10pa / lightbox.player.data.b10pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5pa / lightbox.player.data.b5pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3pa / lightbox.player.data.b3pm, 4, ',', false)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('Počet gólov v PP')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.bapgip / lightbox.player.data.bapm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10pgip / lightbox.player.data.b10pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5pgip / lightbox.player.data.b5pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3pgip / lightbox.player.data.b3pm, 4, ',', false)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('+/-')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.bapp, 0, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10pp, 0, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5pp, 0, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3pp, 0, ',', false)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('Čas na ľade (priemer)')}</TableCell>
                                        <TableCell>{this.time(lightbox.player.data.bapi / lightbox.player.data.bapm)}</TableCell>
                                        <TableCell>{this.time(lightbox.player.data.b10pi / lightbox.player.data.b10pm)}</TableCell>
                                        <TableCell>{this.time(lightbox.player.data.b5pi / lightbox.player.data.b5pm)}</TableCell>
                                        <TableCell>{this.time(lightbox.player.data.b3pi / lightbox.player.data.b3pm)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('Strely formácie hráča (priemer)')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.bapfs / lightbox.player.data.bapm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10pfs / lightbox.player.data.b10pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5pfs / lightbox.player.data.b5pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3pfs / lightbox.player.data.b3pm, 4, ',', false)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div> : null,
                    '',
                    __('Zrušiť'),
                    () => {}
                )}
                {this.renderSnackbar()}
            </div>
        );
    }

    render() {
        return super.render();
    }
}

const stateToProps = ({ results }) => ({ results });

export default withCookies(connect(stateToProps, { fetchResults, setUser })(PublicScreen));
