import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import '../assets/styles/lightbox.css';

class Lightbox extends Component {
    static defaultProps = {
        open: false,
        title: '',
        text: '',
        content: null,
        agreeText: '',
        closeText: '',
        disableClose: false,
        onAgree: () => {},
        onClose: () => {},
        onExit: () => {},
        closeOnAgree: true,
    };

    state = {
        open: false,
        title: '',
    };

    componentDidMount() {
        const { open, title } = this.props;

        this.setState({ open, title });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open !== this.state.open) {
            this.setState({ open: this.props.open });
        } else if (this.props.title !== this.state.title) {
            this.setState({ title: this.props.title });
        }
    }

    onAgree() {
        const { closeOnAgree, onAgree } = this.props;

        if (closeOnAgree) {
            this.setState({ open: false });
        }

        onAgree();
    }

    onClose() {
        this.setState({ open: false });

        this.props.onClose();
    }

    onExit() {
        this.props.onExit();
    }

    renderText(text) {
        return _.map(_.isArray(text) ? text : [text], value => <DialogContentText key={value}>{value}</DialogContentText>);
    }

    render() {
        const { open, title } = this.state;
        const { content, text, agreeText, closeText, disableClose } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.onClose.bind(this)}
                onBackdropClick={!disableClose ? this.onClose.bind(this) : () => {}}
                PaperProps={{ className: 'lightbox' }}
            >
                {!_.isEmpty(title) ? <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle> : null}
                <DialogContent>{content !== null ? content : this.renderText(text)}</DialogContent>
                <DialogActions>
                    {agreeText ? <Button onClick={this.onAgree.bind(this)} color="primary">{agreeText}</Button> : null}
                    {closeText ? <Button onClick={this.onClose.bind(this)} color="primary">{closeText}</Button> : null}
                </DialogActions>
            </Dialog>
        );
    }
}

export { Lightbox };
