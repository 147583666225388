import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';
import { Screen, Input } from '../components';
import { __, request } from '../functions';
import { IMAGES, COOKIE_TOKEN_NAME, COOKIE_SETTINGS } from '../config';
import '../assets/styles/login.css';
import Menu from "../components/Menu";

class LoginScreen extends Screen {
    title = __('Prihlásenie');

    state = {
        email: '',
        password: '',
        loading: false,
    };

    authRequired = false;

    onChangeValue(name, value) {
        this.setState({ [name]: value });
    }

    login() {
        const { email, password } = this.state;

        if (_.isEmpty(email)) {
            this.showSnackbar('error', __('Nevyplnili ste email'));
            return false;
        } else if (_.isEmpty(password)) {
            this.showSnackbar('error', __('Nevyplnili ste heslo'));
            return false;
        }

        this.setState({ loading: true });

        request('/users/login', { email, password }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                this.showSnackbar('error', __('Nesprávne prihlasovacie údaje'));
                return;
            }

            this.afterLogin(data.token);
        });
    }

    afterLogin(token, redirect = '/dashboard') {
        const { cookies } = this.props;

        cookies.set(COOKIE_TOKEN_NAME, token, COOKIE_SETTINGS);

        window.location = redirect;
    }

    render() {
        const { location, navigate, params } = this.props;
        const { email, password, loading } = this.state;

        return (
            <div className="login">
                <Menu
                    location={location}
                    navigate={navigate}
                    params={params}
                />
                <div className="login__window">
                    <img className="login__window__logo" src={IMAGES['logo.jpg']} alt="logo" />
                    <div className="login__window__title">{__('Prihlásenie')}</div>
                    <div className="login__window__text">{__('Pre pokračovanie sa prosím prihláste')}</div>
                    <Input
                        type="email"
                        label={__('Email')}
                        value={email}
                        onChange={value => this.onChangeValue('email', value)}
                    />
                    <Input
                        type="password"
                        label={__('Heslo')}
                        value={password}
                        onChange={value => this.onChangeValue('password', value)}
                    />
                    <ButtonBase
                        onClick={() => this.login()}
                        className="login__window__button"
                        loading={loading}
                    >{__('Prihlásiť')}</ButtonBase>
                    <ButtonBase
                        onClick={() => this.redirect('/register')}
                        className="login__window__button-switch"
                    >{__('Ešte nemáte účet? Registrujte sa.')}</ButtonBase>
                    {this.renderSnackbar()}
                </div>
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(LoginScreen));
