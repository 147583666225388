import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { ButtonBase } from '@mui/material';
import { Screen, Input, Checkbox } from '../components';
import { __, request, isValidEmail } from '../functions';
import { IMAGES, COOKIE_TOKEN_NAME, COOKIE_SETTINGS, PASSWORD_MIN_LENGTH } from '../config';
import '../assets/styles/register.css';
import Menu from "../components/Menu";

class RegisterScreen extends Screen {
    title = __('Registrácia');

    state = {
        email: '',
        password: '',
        passwordRepeat: '',
        vop: false,
        loading: false,
    };

    authRequired = false;

    onChangeValue(name, value) {
        this.setState({ [name]: value });
    }

    register() {
        const { email, password, passwordRepeat, vop } = this.state;

        if (!isValidEmail(email)) {
            this.showSnackbar('error', __('Neplatný email'));
            return;
        } else if (password.length < PASSWORD_MIN_LENGTH) {
            this.showSnackbar(
                'error',
                __('Minimálna dĺžka hesla je {COUNT} znakov').replace('{COUNT}', PASSWORD_MIN_LENGTH.toString())
            );
            return;
        } else if (password !== passwordRepeat) {
            this.showSnackbar('error', __('Heslá nie sú rovnaké'));
            return;
        } else if (!vop) {
            this.showSnackbar('error', __('Musíte súhlasiť so Všeobecnými obchodnými podmienkami'));
            return;
        }

        this.setState({ loading: true });

        request('/users/register', {
            email,
            password,
            password_repeat: passwordRepeat,
        }, 'POST').then(async response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.setState({ loading: false });
                this.showSnackbar('error', __('Email sa už používa'));
                return;
            }

            this.setState({ loading: false });

            this.afterRegister(data.token);
        });
    }

    afterRegister(token) {
        const { cookies } = this.props;

        cookies.set(COOKIE_TOKEN_NAME, token, COOKIE_SETTINGS);

        window.location = '/dashboard';
    }

    render() {
        const { location, navigate, params } = this.props;
        const { email, password, passwordRepeat, vop, loading } = this.state;

        const vopUrl = '/terms.pdf?v=1';

        return (
            <div className="register">
                <Menu
                    location={location}
                    navigate={navigate}
                    params={params}
                />
                <div className="register__window">
                    <img className="register__window__logo" src={IMAGES['logo.jpg']} alt="logo" />
                    <div className="register__window__title">{__('Registrácia')}</div>
                    <div className="register__window__text">{__('Pre pokračovanie sa prosím registrujte')}</div>
                    <Input
                        type="email"
                        label={__('Email')}
                        value={email}
                        onChange={value => this.onChangeValue('email', value)}
                    />
                    <Input
                        type="password"
                        label={__('Heslo')}
                        value={password}
                        onChange={value => this.onChangeValue('password', value)}
                    />
                    <Input
                        type="password"
                        label={__('Heslo znovu')}
                        value={passwordRepeat}
                        onChange={value => this.onChangeValue('passwordRepeat', value)}
                    />
                    <Checkbox
                        label={<span>
                        <span>{__('Súhlasím so')} </span>
                        <a href={vopUrl} target="_blank">{__('Všeobecnými obchodnými podmienkami')}</a>
                    </span>}
                        value={vop}
                        onChange={checked => this.onChangeValue('vop', checked)}
                    />
                    <ButtonBase
                        onClick={() => this.register()}
                        className="register__window__button"
                        loading={loading}
                    >{__('Registrovať')}</ButtonBase>
                    <ButtonBase
                        onClick={() => this.redirect('/login')}
                        className="register__window__button-switch"
                    >{__('Už máte účet? Prihláste sa.')}</ButtonBase>
                    {this.renderSnackbar()}
                </div>
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps)(RegisterScreen));
