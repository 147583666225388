import React from 'react';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from './Navigate';
import { __, getUrlPath } from '../functions';
import {COOKIE_TOKEN_NAME, COOKIE_SETTINGS} from '../config';

class Screen extends Navigate {
    title = '';
    authRequired = true;

    async componentDidMount() {
        this.scrollToTop();

        const { cookies } = this.props;

        if (this.getPath() === '/logout') {
            cookies.remove(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

            window.location = getUrlPath('/');
            return false;
        }

        this.setTitle(this.title);
        this.setToken();

        if (this.authRequired && _.isEmpty(global.token)) {
            window.location = '/login';
            return false;
        } else if (!this.authRequired && !_.isEmpty(global.token)) {
            // Nevyzaduje si auth a mame token

            window.location = '/dashboard';
            return false;
        }

        await this.fetch();

        return true;
    }

    setToken() {
        const { cookies } = this.props;

        const token = cookies.get(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

        global.token = !_.isUndefined(token) ? token : '';
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    fetch() {
        if (!_.has(this.props, 'fetchResults') || !_.has(this.props, 'results')) {
            return;
        }

        const { fetchResults } = this.props;

        if (!_.isEmpty(this.props.results)) {
            return;
        }

        // Load data
        fetchResults(this);
    }

    setTitle(title) {
        const domain_name = __('detectgoals.com');

        document.title = !_.isEmpty(title) ? `${title} - ${domain_name}` : domain_name;
    }

    renderScreen() {
        return null;
    }

    renderLoading() {
        return (
            <div id="screen-loading">
                <CircularProgress color="primary" size={50} />
            </div>
        );
    }

    render() {
        if (_.has(this.props, 'results') && !_.isEmpty(this.props.results)) {
            return this.renderScreen();
        }

        return this.renderLoading();
    }
}

export { Screen };
