import React, { Component } from 'react';
import _ from 'lodash';
import ReactCountryFlag from 'react-country-flag';
import { MenuItem, FormControl } from '@mui/material';
import MainSelect from '@mui/material/Select';
import { __ } from '../functions';
import '../assets/styles/select.css';

class Select extends Component {
    static defaultProps = {
        label: '',
        value: '',
        options: {},
        onChange: () => {},
        allowEmpty: true,
        placeholder: null,
        variant: 'filled',
        disabled: false,
        strict: true,
        className: '',
        multiple: false,
        countries: false,
        phones: false,
    };

    state = {
        value: '',
    };

    componentDidMount() {
        this.setState({ value: this.formatValue(this.props.value) });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const value = this.formatValue(this.props.value);

        if (this.state.value !== value) {
            this.setState({ value });
        }
    }

    onChange(value) {
        const { onChange, multiple } = this.props;

        this.setState({ value });

        if (multiple) {
            value = this.getMultipleValue(value);

            if (value.length > 0) {
                value = _.join(value, '===');
            } else {
                value = '';
            }
        }

        onChange(value);
    }

    formatValue(value) {
        const { strict } = this.props;

        if (strict && (value === 0 || value === '0')) {
            return '';
        }

        return value;
    }

    formatOption(option, key) {
        const { countries, phones } = this.props;

        if (countries || phones) {
            return (
                <div className="select__flag">
                    <ReactCountryFlag countryCode={key.toUpperCase()} svg />
                    <span>{phones ? '+' : ''}{option}</span>
                </div>
            );
        }

        if (option.indexOf('https://') !== -1) {
            return <img src={option} height={30} alt={option} />;
        }

        switch (option) {
            case 'sk':
                return 'Slovakia';

            case 'hu':
                return 'Hungary';

            default:
                return option;
        }
    }

    getMultipleValue(value) {
        value = !_.isArray(value) ? _.split(_.toString(value), '===') : value;
        value = _.remove(value, v => v !== '');

        return value.length > 0 ? value : [];
    }

    render() {
        const { label, options, allowEmpty, placeholder, variant, disabled, className, multiple } = this.props;
        const { value } = this.state;

        return (
            <div className={`select ${disabled ? 'disabled' : ''} ${className}`}>
                {label !== '' ? <div className="select__label">{label}</div> : null}
                <FormControl variant={variant}>
                    <MainSelect
                        className="select__container"
                        value={multiple ? this.getMultipleValue(value) : value}
                        onChange={event => this.onChange(event.target.value)}
                        displayEmpty={placeholder !== null}
                        disabled={disabled}
                        multiple={multiple}
                    >
                        {allowEmpty ? <MenuItem value="">
                            <em>{placeholder !== null ? placeholder : __('Žiadne')}</em>
                        </MenuItem> : null}
                        {_.map(_.keys(options), key => <MenuItem key={key} value={key}>{this.formatOption(options[key], key)}</MenuItem>)}
                    </MainSelect>
                </FormControl>
            </div>
        );
    }
}

export { Select };
