import React, { Component } from 'react';
import _ from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import MainCheckbox from '@mui/material/Checkbox';
import { toNumber } from '../functions';
import '../assets/styles/checkbox.css';

/**
 * Checkbox component.
 */
class Checkbox extends Component {
    static defaultProps = {
        label: '',
        value: '',
        disabled: false,
        onChange: () => {},
    };

    state = {
        value: '',
    };

    componentDidMount() {
        const { value } = this.props;

        this.setState({ value });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    onChange(value) {
        this.setState({ value });

        this.props.onChange(value);
    }

    render() {
        const { label, value, disabled } = this.props;

        return <FormControlLabel
            control={
                <MainCheckbox
                    checked={_.isBoolean(value) ? value : toNumber(value) > 0}
                    onChange={event => this.onChange(event.target.checked)}
                    disabled={disabled}
                    color="primary"
                />
            }
            label={label}
            className="checkbox"
        />;
    }
}

export { Checkbox };
