import _ from 'lodash';
import { toNumber } from './toNumber';
import { toFixed } from './toFixed';

export const formatAmount = (amount, decimals = 2, separator = ',', currency = true) => {
    amount = /(-|)(.*)\.(.*)/.exec(toFixed(toNumber(amount), decimals));

    amount[2] = _.join(_.reverse(_.split(_.replace(_.join(_.reverse(_.split(amount[2], '')), ''), /(\d{3})(?=\d)/g, '$1 '), '')), '');

    return `${amount[1]}${amount[2]}${amount[3] === '' ? '' : separator}${amount[3]}${currency ? ` €` : ''}`;
};
