import React, { Component } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MainSwitch from '@mui/material/Switch';

class Switch extends Component {
    static defaultProps = {
        label: '',
        color: 'primary',
        checked: false,
        onChange: () => {},
    };

    state = {
        checked: false,
    };

    componentDidMount() {
        const { checked } = this.props;

        this.setState({ checked });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.checked !== this.props.checked) {
            this.setState({ checked: this.props.checked });
        }
    }

    onChange(checked) {
        this.setState({ checked });

        this.props.onChange(checked);
    }

    render() {
        const { label, color } = this.props;
        const { checked } = this.state;

        return (
            <FormControlLabel
                control={
                    <MainSwitch
                        checked={checked}
                        onChange={event => this.onChange(event.target.checked)}
                        color={color}
                    />
                }
                label={label}
            />
        );
    }
}

export { Switch };
