import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Navigate } from './Navigate';
import '../assets/styles/footer.css';
import {ButtonBase} from "@mui/material";

class Footer extends Navigate {

    render() {
        const { results } = this.props;

        if (_.isEmpty(results)) {
            //return null;
        }

        return (
            <div className="footer">
                <div className="footer__content">
                    <div className="footer__content__left">
                        <ButtonBase onClick={() => {}}>Terms and conditions</ButtonBase>
                        <ButtonBase onClick={() => {}}>Privacy and cookie</ButtonBase>
                    </div>
                    <div className="footer__content__right">
                        <ButtonBase onClick={() => window.open('mailto:info@detectgoals.com', '_blank')}>info@detectgoals.com</ButtonBase>
                    </div>
                </div>
            </div>
        );
    }
}

const stateToProps = ({ results }) => ({ results });

export default withCookies(connect(stateToProps)(Footer));
