import { FETCH_RESULTS } from './types';
import { request } from '../functions';

export const fetchResults = (screen = '') => {
    return dispatch => request('/results/history').then(({ data }) => {
        const { user } = data.sync;
        const { setUser } = screen.props;

        // Nasetujeme usera
        setUser(user);

        dispatch({ type: FETCH_RESULTS, payload: data.data });
    });
};
