import { FETCH_DASHBOARD } from '../actions/types';

const DASHBOARD_DEFAULT_STATE = [];

const DashboardReducer = (state = DASHBOARD_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_DASHBOARD:
            return payload;

        default:
            return state;
    }
};

export default DashboardReducer;
