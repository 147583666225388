import React, { Component } from 'react';
import _ from 'lodash';
import { Snackbar, CircularProgress } from '@mui/material';
import { Lightbox } from './Lightbox';
import { Message } from './Message';
import { getUrlPath } from '../functions';

class Navigate extends Component {

    redirect(path) {
        this.props.navigate(getUrlPath(path));
    }

    isMobile() {
        return window.innerWidth < 1200;
    }

    getPath() {
        return this.props.location.pathname;
    }

    showSnackbar(type, message) {
        this.setState({ snackbar: { ...this.state.snackbar, ...{ type, message } } });
    }

    onCloseSnackbar() {
        this.setState({ snackbar: { ...this.state.snackbar, type: null } });
    }

    showLightbox(type, data = null) {
        this.setState({ lightbox: { ...this.state.lightbox, [type]: data !== null ? data : true } });
    }

    closeLightbox(type) {
        this.onCloseLightbox(type);
    }

    onCloseLightbox(type) {
        this.setState({ lightbox: { ...this.state.lightbox, [type]: false } });
    }

    renderLightbox(
        type,
        title,
        content,
        agreeText = '',
        closeText = '',
        onAgree = null,
        disableClose = false,
        closeOnAgree = true
    ) {
        return <Lightbox
            title={title}
            content={content}
            open={_.isBoolean(this.state.lightbox[type]) ? this.state.lightbox[type] : true}
            agreeText={agreeText}
            closeText={closeText}
            onAgree={onAgree}
            onClose={this.onCloseLightbox.bind(this, type)}
            disableClose={disableClose}
            closeOnAgree={closeOnAgree}
        />;
    }

    renderSnackbar() {
        if (!_.has(this.state, 'snackbar')) {
            return null;
        }

        const { type, message } = this.state.snackbar;

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={!_.isEmpty(type)}
                autoHideDuration={6000}
                onClose={this.onCloseSnackbar.bind(this)}
                style={{ zIndex: 5000000 }}
                message={<Message type={type}>{message}</Message>}
            />
        );
    }

    renderButtonLoading() {
        return <CircularProgress color="inherit" size={20} />;
    }
}

export { Navigate };
