import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import {Message, Screen} from '../components';
import {__, formatAmount, toNumber} from '../functions';
import '../assets/styles/dashboard.css';
import Menu from "../components/Menu";
import {fetchDashboard, setUser} from '../actions';
import _ from 'lodash';
import {ButtonBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import ReadMoreIcon from "@mui/icons-material/ReadMore";

class DashboardScreen extends Screen {
    title = __('Dashboard');
    state = {
        lightbox: {
            player: false,
        },
    };

    componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchDashboard } = this.props;

            fetchDashboard(this);
        }
    }

    time(seconds) {
        seconds = Math.ceil(seconds);

        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${minutes}:${seconds}`;
    }

    render() {
        const { dashboard, location, navigate, params } = this.props;
        const { lightbox } = this.state;

        if (_.isEmpty(dashboard)) {
            return this.renderLoading();
        }

        return (
            <div className="dashboard">
                <Menu
                    location={location}
                    navigate={navigate}
                    params={params}
                />
                <div className="dashboard__subtitle">{__('Udalosti')}</div>
                <div className="dashboard__subtext">{__('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et feugiat odio. Nunc tempor malesuada tellus.')}</div>
                {_.isEmpty(dashboard.players) ? <Message type="info" className="dashboard__emptymessage">{__('Aktuálne nie sú dostupné žiadne udalosti.')}</Message> : null}
                <div className="dashboard__players">
                    {_.map(dashboard.players, (item, key) => {
                        return (
                            <ButtonBase onClick={() => this.showLightbox('player', { data: item })} className="dashboard__players__player" key={key}>
                                <div className="dashboard__players__player__date">{item.md}</div>
                                <div className="dashboard__players__player__info">
                                    <div className="dashboard__players__player__info__bio">
                                        <div className="dashboard__players__player__info__bio__name">{item.pn}</div>
                                        <div className="dashboard__players__player__info__bio__team">{item.pt} {__('vs')} {item.ot}</div>
                                    </div>
                                    <div className="dashboard__players__player__info__stats">
                                        <div className="dashboard__players__player__info__stats__stat">{__('Formácia')}: {item.pf}</div>
                                        <div className="dashboard__players__player__info__stats__stat">{__('AI ranking')}: {formatAmount(item.p, 8, ',', false)}</div>
                                    </div>
                                    <ReadMoreIcon />
                                </div>
                                <div className="dashboard__players__player__right" />
                            </ButtonBase>
                        );
                    })}
                </div>
                {this.renderLightbox(
                    'player',
                    __('Základné štatistiky hráča'),
                    !_.isEmpty(lightbox.player) ? <div className="player__window">
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>{__('Celkovo')}</TableCell>
                                        <TableCell>{__('10 zápasov')}</TableCell>
                                        <TableCell>{__('5 zápasov')}</TableCell>
                                        <TableCell>{__('3 zápasy')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{__('Počet gólov (priemer)')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.bapg / lightbox.player.data.bapm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10pg / lightbox.player.data.b10pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5pg / lightbox.player.data.b5pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3pg / lightbox.player.data.b3pm, 4, ',', false)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('Počet striel (priemer)')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.baps / lightbox.player.data.bapm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10ps / lightbox.player.data.b10pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5ps / lightbox.player.data.b5pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3ps / lightbox.player.data.b3pm, 4, ',', false)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('Počet asistencií (priemer)')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.bapa / lightbox.player.data.bapm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10pa / lightbox.player.data.b10pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5pa / lightbox.player.data.b5pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3pa / lightbox.player.data.b3pm, 4, ',', false)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('Počet gólov v PP')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.bapgip / lightbox.player.data.bapm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10pgip / lightbox.player.data.b10pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5pgip / lightbox.player.data.b5pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3pgip / lightbox.player.data.b3pm, 4, ',', false)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('+/-')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.bapp, 0, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10pp, 0, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5pp, 0, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3pp, 0, ',', false)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('Čas na ľade (priemer)')}</TableCell>
                                        <TableCell>{this.time(lightbox.player.data.bapi / lightbox.player.data.bapm)}</TableCell>
                                        <TableCell>{this.time(lightbox.player.data.b10pi / lightbox.player.data.b10pm)}</TableCell>
                                        <TableCell>{this.time(lightbox.player.data.b5pi / lightbox.player.data.b5pm)}</TableCell>
                                        <TableCell>{this.time(lightbox.player.data.b3pi / lightbox.player.data.b3pm)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{__('Strely formácie hráča (priemer)')}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.bapfs / lightbox.player.data.bapm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b10pfs / lightbox.player.data.b10pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b5pfs / lightbox.player.data.b5pm, 4, ',', false)}</TableCell>
                                        <TableCell>{formatAmount(lightbox.player.data.b3pfs / lightbox.player.data.b3pm, 4, ',', false)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div> : null,
                    '',
                    __('Zrušiť'),
                    () => {}
                )}
            </div>
        );
    }
}

const stateToProps = ({ dashboard, user }) => ({ dashboard, user });

export default withCookies(connect(stateToProps, { fetchDashboard, setUser })(DashboardScreen));
