import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Navigate } from '../components';
import '../assets/styles/menu.css';
import {IMAGES} from "../config";
import {ButtonBase, Tooltip, IconButton} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import { __ } from '../functions';
import { COOKIE_TOKEN_NAME, COOKIE_SETTINGS } from "../config";

class Menu extends Navigate {
    static defaultProps = {
    };

    /**
     * Odhlasenie.
     */
    logout() {
        const { cookies } = this.props;

        // Zmazeme token
        cookies.remove(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

        window.location = '/login';
    }

    render() {
        const { results, user } = this.props;

        if (_.isEmpty(results)) {
            //return null;
        }

        const path = this.getPath();
        const activeLogin = path === '/login';
        const activeRegister = path === '/register';
        const activeDashboard = path === '/dashboard';

        return (
            <div className="menu">
                <div className="menu__header">
                    <div className="menu__header__content">
                        <div className="menu__header__content__left">
                            <ButtonBase onClick={() => {}}>
                                <ReactCountryFlag countryCode="US" svg style={{ width: '1rem', height: '1rem' }} title="United states" />
                                <ReactCountryFlag countryCode="CA" svg style={{ width: '1rem', height: '1rem' }} title="Canada" />
                            </ButtonBase>
                            <ButtonBase onClick={() => {}}>
                                <ReactCountryFlag countryCode="SE" svg style={{ width: '1rem', height: '1rem' }} title="Sweden" />
                            </ButtonBase>
                            <ButtonBase onClick={() => {}}>
                                <ReactCountryFlag countryCode="FI" svg style={{ width: '1rem', height: '1rem' }} title="Finland" />
                            </ButtonBase>
                            <ButtonBase onClick={() => {}}>
                                <ReactCountryFlag countryCode="DE" svg style={{ width: '1rem', height: '1rem' }} title="Germany" />
                            </ButtonBase>
                            <ButtonBase onClick={() => {}}>
                                <ReactCountryFlag countryCode="CH" svg style={{ width: '1rem', height: '1rem' }} title="Switzerland" />
                            </ButtonBase>
                            <ButtonBase onClick={() => {}}>
                                <ReactCountryFlag countryCode="CZ" svg style={{ width: '1rem', height: '1rem' }} title="Czechia" />
                            </ButtonBase>
                            <ButtonBase onClick={() => {}}>
                                <ReactCountryFlag countryCode="SK" svg style={{ width: '1rem', height: '1rem' }} title="Slovakia" />
                            </ButtonBase>
                        </div>
                        <div className="menu__header__content__right">
                            <ButtonBase onClick={() => {}} className="active">USD</ButtonBase>
                            <ButtonBase onClick={() => {}}>EUR</ButtonBase>
                        </div>
                    </div>
                </div>
                <div className="menu__content">
                    <div className="menu__content__logo">
                        <img src={IMAGES['logo.jpg']} alt="logo" />
                    </div>
                    {_.isEmpty(user) ? <div className="menu__content__buttons">
                        <ButtonBase onClick={() => this.redirect('/')} className={!activeLogin && !activeRegister ? 'active' : ''}>{__('AI Výsledky')}</ButtonBase>
                        <ButtonBase onClick={() => {}}>{__('AI predikčný model')}</ButtonBase>
                        <ButtonBase onClick={() => {}}>{__('Cenník')}</ButtonBase>
                        <ButtonBase onClick={() => {}}>{__('FAQ')}</ButtonBase>
                        <ButtonBase onClick={() => this.redirect('/login')} className={activeLogin ? 'active' : ''}>{__('Prihlásenie')}</ButtonBase>
                    </div> : null}
                    {!_.isEmpty(user) ? <div className="menu__content__buttons">
                        <ButtonBase onClick={() => this.redirect('/dashboard')} className={activeDashboard ? 'active' : ''}>{__('Dashboard')}</ButtonBase>
                    </div> : null}
                    <div className="menu__content__right">
                        {_.isEmpty(user) ? <ButtonBase
                            onClick={() => this.redirect('/register')}
                            className="menu__content__right__register"
                        >{__('Registrácia')}</ButtonBase> : null}
                        {!_.isEmpty(user) ? <Tooltip title={__('Odhlásenie')}>
                            <IconButton
                                onClick={() => this.logout()}
                                className="menu__content__right__logout"
                            >
                                <LogoutIcon />
                            </IconButton>
                        </Tooltip> : null}
                    </div>
                </div>
                {_.isEmpty(user) ? <div className="menu__submenu">
                    <ButtonBase onClick={() => this.redirect('/')} className={!activeLogin && !activeRegister ? 'active' : ''}>{__('AI Výsledky')}</ButtonBase>
                    <ButtonBase onClick={() => {}}>{__('AI predikčný model')}</ButtonBase>
                    <ButtonBase onClick={() => {}}>{__('Cenník')}</ButtonBase>
                    <ButtonBase onClick={() => {}}>{__('FAQ')}</ButtonBase>
                    <ButtonBase onClick={() => this.redirect('/login')} className={activeLogin ? 'active' : ''}>{__('Prihlásenie')}</ButtonBase>
                </div> : null}
                {!_.isEmpty(user) ? <div className="menu__submenu">
                    <ButtonBase onClick={() => this.redirect('/dashboard')} className={activeDashboard ? 'active' : ''}>{__('Dashboard')}</ButtonBase>
                </div> : null}
                <div className="menu__discount">{__('Zľava 50% na kúpu členstva na novú sezónu do 01.05.2025!')}</div>
            </div>
        );
    }
}

const stateToProps = ({ results, user }) => ({ results, user });

export default withCookies(connect(stateToProps)(Menu));
